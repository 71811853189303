import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ProtectedRoute from "../_util/ProtectedRoute";
import ProtectedLogin from "../_util/ProtectedLogin";
import ProtectedSignUp from "../_util/ProtectedSignUp";
import PageLoader from "../core/FullPageLoader";

// Lazy loading components
const Login = lazy(() => import("../pages/auth/login"));
const Dashboard =lazy(()=>import("../pages/Dashboard"))
const NotAllowed = lazy(() => import("../pages/error/unauthorizeduser"));

const Signup = lazy(() => import("../pages/auth/signup"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPass"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPass"));

const UserProfile = lazy(() => import("../pages/user-profile/userProfile"));
const FranchiseCalender = lazy(() => import("../pages/Franchise/calendar/calendar"));
const TokenStatistics = lazy(() => import("../pages/Franchise/token-statistics/tokenStatistics"));
const GiftToken = lazy(() => import("../pages/Franchise/gift-token/list"));
const MemberActivityList = lazy(() => import("../pages/Franchise/member-activity/list"));
const MemberActivityDetail = lazy(() => import("../pages/Franchise/member-activity/detail"));
const EventType = lazy(() => import("../pages/event-type/list"));
const EventTypeDetail = lazy(() => import("../pages/event-type/detail"));
const RoleForm = lazy(() => import("../pages/settings/roles/form"));
const RoleList = lazy(() => import("../pages/settings/roles/list"));
const UsersList = lazy(() => import("../pages/settings/users/list"));
const UsersForm = lazy(() => import("../pages/settings/users/form"));
const PaddleBuddyList = lazy(() => import("../pages/paddle-buddy/list"));
const PaddleBuddyDetail = lazy(() => import("../pages/paddle-buddy/detail"));
const Wallet = lazy(()=> import("../pages/Member/wallet/wallet"));

export const AppRoutes = (props) => {
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  
  const IsAllowedToRender = (module, func, route_type) => {
    let moduleDetail = userRoles.find(r => r.moduleName?.toLowerCase() == module);
    if(moduleDetail)
      {
        //let func_name = func == "user" ? "users" : curr_location[1] == "carrier" ? "carriers" : curr_location[1] == "role" ? "roles" : curr_location[1];
        let role_function = moduleDetail.functions.find(x => x.functionName?.toLowerCase() == func);
        if(route_type.includes("detail") || route_type.includes("list") || route_type == "view"){
          return role_function?.actions[0]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Upload RMA"){
          return role_function?.actions[1]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Stock Transfer"){
          return role_function?.actions[2]?.value;
        }
        else if(route_type == "form" &&role_function.functionName==="Pick"){
          return role_function?.actions[4]?.value;   
        }
        else if(route_type == "form"){
          return role_function?.actions[3]?.value;
        }
         else if(route_type == "form-edit" || role_function.functionName==="Pack" ){
          return role_function?.actions[4]?.value;
        }
        else if(route_type == "form-edit"){
          return role_function?.actions[1]?.value;
        }
      }
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<ProtectedLogin><Login /></ProtectedLogin>}></Route>
        <Route path="login" element={<ProtectedLogin><Login /></ProtectedLogin>}></Route>
        <Route path="login/admin" element={<Login />}></Route>
        <Route path="login/franchisee" element={<Login />}></Route>
        <Route path="signup/:id" element={<ProtectedSignUp><Signup /></ProtectedSignUp>}></Route>
        <Route path="forgotpassword" element={<ProtectedLogin><ForgotPassword /></ProtectedLogin>}></Route>
        <Route path="password-reset" element={<ResetPassword />}></Route>
        <Route path="AccessNotAllowed" element={<NotAllowed />}></Route>
        <Route path="profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>}></Route>
        <Route path="franchise/calendar" element={<ProtectedRoute><FranchiseCalender /></ProtectedRoute>}></Route>
        <Route path="franchise/token-statistics" element={<ProtectedRoute><TokenStatistics /></ProtectedRoute>}></Route>
        <Route path="franchise/gift-token" element={<ProtectedRoute><GiftToken /></ProtectedRoute>}></Route>
        <Route path="franchise/member-activity" element={<ProtectedRoute><MemberActivityList/></ProtectedRoute>}></Route>
        <Route path="franchise/member-activity/detail/:id" element={<ProtectedRoute><MemberActivityDetail /></ProtectedRoute>}></Route>
        <Route path="settings/role" element={<ProtectedRoute><RoleList /></ProtectedRoute>}></Route>
        <Route path="settings/role/detail/:id" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="settings/role/form" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="settings/role/form/:id" element={<ProtectedRoute><RoleForm /></ProtectedRoute>}></Route>
        <Route path="event-type" element={<ProtectedRoute><EventType /></ProtectedRoute>}></Route>
        <Route path="event-type/detail/:id" element={<ProtectedRoute><EventTypeDetail /></ProtectedRoute>}></Route>
        <Route path="settings/user" element={<ProtectedRoute><UsersList /></ProtectedRoute>}></Route>
        <Route path="settings/user/detail/:id" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="settings/user/form" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="settings/user/form/:id" element={<ProtectedRoute><UsersForm /></ProtectedRoute>}></Route>
        <Route path="paddle-buddy" element={<ProtectedRoute><PaddleBuddyList /></ProtectedRoute>}></Route>
        <Route path="paddle-buddy/detail/:id" element={<ProtectedRoute><PaddleBuddyDetail /></ProtectedRoute>}></Route>
        <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}></Route>
        <Route path="wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>}></Route>
        {/* <Route path="receiving">
          <Route path="create-manifest" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","create manifest", "view")}><CreateManifest /></ProtectedRoute>}></Route>
          <Route path="create-manifest/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","create manifest/:id", "view")}><CreateManifest /></ProtectedRoute>}></Route>
          <Route path="pack-list" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","pack list", "view")}><PackList /></ProtectedRoute>}></Route>

          <Route path="received-rma">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","received rma", "list")}><ReceivedRMA /></ProtectedRoute>} />
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","received rma", "detail")}><ReceivedRMADetail /></ProtectedRoute>} />
          </Route>

          <Route path="scan-rma" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","scan rma", "list")}><ScanRMA /></ProtectedRoute>}></Route>
          <Route path="ScanRmaDetail" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","scan rma", "list")}><ScanRmaDetail /></ProtectedRoute>}></Route>

          <Route path="upload-rma">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","upload rma", "list")}><UploadRMA /></ProtectedRoute>} />
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","upload rma", "detail")}><UploadRMADetail /></ProtectedRoute>} />
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","upload rma", "form-edit")}><UploadRMAForm /></ProtectedRoute>} />
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("receiving","upload rma", "form")}><UploadRMAForm /></ProtectedRoute>} />
          </Route>
        </Route> */}

        {/* <Route path="inventory">
          <Route path="inventory-by-item" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","inventory by item", "list")}><InventorybyItem /></ProtectedRoute>} />
          <Route path="stock-transfer">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","stock transfer", "list")}><StockTransfer /></ProtectedRoute>} />
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","stock transfer", "form")}><StockTransferForm /></ProtectedRoute>} />
            <Route path="create/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","stock transfer", "form")}><StartStockTransfer /></ProtectedRoute>} />
          </Route>
          <Route path="inventory-by-pallet" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","inventory by pallet", "list")}><InventorybyPallet /></ProtectedRoute>} />
          <Route path="inventory-by-pallet-detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("inventory","inventory by pallet", "detail")}><InventoryPalletDetail /></ProtectedRoute>} />
        </Route>
 */}
        {/* <Route path="outbound">
          <Route path="fulfillment-request" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","fulfillment", "list")}><FulfillmentRequest /></ProtectedRoute>}></Route>
          <Route path="fulfillment-request/detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","fulfillment", "detail")}><FulfillmentRequestDetail /></ProtectedRoute>}></Route>
          <Route path="pick" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pick", "list")}><Pick /></ProtectedRoute>}></Route>
          <Route path="pick/detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pick", "detail")}><PickDetail /></ProtectedRoute>}></Route>
          <Route path="pick/form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pick", "form")}><PickProcess /></ProtectedRoute>}></Route>
          
          <Route path="pack">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pack", "list")}><Pack /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pack", "form")}><PackProcess /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pack", "form-edit")}><PackProcess /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","pack", "detail")}><PackDetail /></ProtectedRoute>}></Route>
          </Route>
          
          <Route path="ship" element={<ProtectedRoute allowRender={IsAllowedToRender("outbound","ship", "list")}><Ship /></ProtectedRoute>}></Route>
        </Route> */}

        {/* <Route path='order'>
          <Route path="sale-request">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales request", "list")}><SalesRequest /></ProtectedRoute>} />
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales request", "form")}><SalesRequestForm /></ProtectedRoute>} />
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales request", "form-edit")}><SalesRequestForm /></ProtectedRoute>} />
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales request", "detail")}><SalesRequestDetail /></ProtectedRoute>}></Route>
            <Route path="all-notification" element={<ProtectedRoute allowRender={true}><DetailNotification /></ProtectedRoute>} />
          </Route>
          <Route path="sale-order">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales order", "list")}><SalesOrder /></ProtectedRoute>} />
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales order", "form")}><SalesOrderForm /></ProtectedRoute>} />
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales order", "form")}><SalesOrderForm /></ProtectedRoute>} />
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("orders","sales order", "detail")}><SalesOrderDetail /></ProtectedRoute>} />
          </Route>
        </Route>

        <Route path='report'>
          <Route path="rma-report">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("reports","rma-report", "list")}><RMAReports/></ProtectedRoute>} />
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("reports","rma-report", "detail")}><RMAReportsDetail /></ProtectedRoute>} />

                   </Route>
          
        </Route> */}

        {/* <Route path="setup">
          <Route path="item">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","item master", "list")}><ItemMasterList /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","item master", "form")}><ItemMasrterForm /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","item master", "form-edit")}><ItemMasrterForm /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","item master", "detail")}><ItemMasrterDetail /></ProtectedRoute>}></Route>
          </Route>
          <Route path="user">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","users", "list")}><UserSetupList /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","users", "form")}><UserSetupForm /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","users", "form-edit")}><UserSetupForm /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","users", "detail")}><UserDetailForm /></ProtectedRoute>}></Route>
          </Route>
          <Route path="role">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","roles", "list")}><UserRoleList /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","roles", "form")}><UserRoleForm /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","roles", "form-edit")}><UserRoleForm /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","roles", "detail")}><UserRoleDetail /></ProtectedRoute>}></Route>
          </Route>
          <Route path="warehouse">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "list")}><WarehouseList /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "detail")}><WarehouseDetail /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form")}><WarehouseForm /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form-edit")}><WarehouseForm /></ProtectedRoute>}></Route>
            <Route path="zone/list/:warehouseId" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "list")}><ZoneSetupList /></ProtectedRoute>}></Route>
            <Route path="zone/form/:warehouseId" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form")}><ZoneSetupForm /></ProtectedRoute>}></Route>
            <Route path="zone/form/:warehouseId/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form-edit")}><ZoneSetupForm /></ProtectedRoute>}></Route>
            <Route path="zone/detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "detail")}><ZoneDetail /></ProtectedRoute>}></Route>
            <Route path="location/list/:ZoneID" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "list")}><LocationList /></ProtectedRoute>}></Route>
            <Route path="location/form/:ZoneID/:zoneTypeID" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form")}><LocationSetupForm /></ProtectedRoute>}></Route>
            <Route path="location/form/:ZoneID/:zoneTypeID/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "form-edit")}><LocationSetupForm /></ProtectedRoute>}></Route>
            <Route path="location/detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","warehouse", "detail")}><LocationDetail /></ProtectedRoute>}></Route>
          </Route>
          <Route path="carrier">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","carriers", "list")}><CarrierList /></ProtectedRoute>}></Route>
            <Route path="form" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","carriers", "form")}><CarrierForm /></ProtectedRoute>}></Route>
            <Route path="form/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","carriers", "form-edit")}><CarrierForm /></ProtectedRoute>}></Route>
            <Route path="detail/:id" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","carriers", "detail")}><CarrierDetailForm /></ProtectedRoute>}></Route>
          </Route>
          <Route path="customer">
            <Route path="list" element={<ProtectedRoute allowRender={IsAllowedToRender("setup","customers", "list")}><CustomerList /></ProtectedRoute>}></Route>
          
          </Route>
        </Route> */}

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>

  );
};